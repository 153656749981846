import React from 'react';
import { Link } from 'react-router-dom';

const DeleteTask = (props) => {
    return (
        <div className="confirmation main">
            <div className="grey-box text-center box-seventy-percent margin-top">
                <h1>Are you sure you want to delete the task<br/>"Clean Up Søerne"?</h1>
                <p>This action cannot be undone.<br/>All signed up volunteers will be notified about the cancellation.</p>
                <div className="text-center">
                    <Link to="/task/id" className="button-green button-hollow">No, Back to task</Link>
                </div>
                <div className="text-center">
                    <Link to="/task/id" className="button-green">Yes, Delete Task</Link>
                </div>
            </div>
        </div>
    );
}

export default DeleteTask;
