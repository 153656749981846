import React from 'react';
import { Link, Route } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faUsers, faTrash } from '@fortawesome/free-solid-svg-icons';

import './TaskView.css';
import TaskViewDetails from './TaskViewDetails';
import TaskViewVolunteers from './TaskViewVolunteers';
import EditTask from './EditTask';
import DeleteTask from './DeleteTask';

const TaskView = (props) => {
    return (
        <div className="main taskview">
            <nav className="taskview-nav">
                <Link to="/task/id"><FontAwesomeIcon icon={faEye} /> View Task</Link>
                <Link to="/task/id/edit"><FontAwesomeIcon icon={faEdit} /> Edit Task</Link>
                <Link to="/task/id/volunteers"><FontAwesomeIcon icon={faUsers} /> View Volunteers</Link>
                <Link to="/task/id/delete"><FontAwesomeIcon icon={faTrash} /> Delete Task</Link>
            </nav>
            <Route path="/task/id" exact component={TaskViewDetails} />
            <Route path="/task/id/volunteers" component={TaskViewVolunteers} />
            <Route path="/task/id/edit" component={EditTask} />
            <Route path="/task/id/delete" component={DeleteTask} />
        </div>
    );
}

export default TaskView;
