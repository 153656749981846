import React from 'react';
import { Link } from 'react-router-dom';
import guide from './guide.jpg'

const OrganizerGuide = (props) => {
    return (
        <div className="home-wrapper">
            <img src={guide} className='home-banner-image' />
            <div className="main">
                <div className="home-text-wrapper">
                    <h1 className="text-center margin-top">Guide for Organizers</h1>
                    <h3 className="margin-top">1. Create an account/Log in</h3>
                    <p>You need to have an account and be logged in to offer tasks. If you don't already have an account, register <Link to="/sign-up">here</Link>.</p>
                    <h3 className="margin-top">2. Create New Task</h3>
                    <p>Create a new task and volunteers will be able to sign up for it! Simply navigate to your <Link to="/dashboard">Dashboard</Link>, where you can create a new task from scratch or copy a previous one and make adjustments to it. If your task is happening regulary, you can also make it recurring. Creating a task is easy, just fill out the required fields, just as title, location, time, spots available and description, and you are all set! Optionally, you can chose an image to make it stick out more.</p>
                    <h3 className="margin-top">3. Adjustments and communication</h3>
                    <p>You have some changes for the meeting point or want to let your volunteers know to be prepared for rain? No problem - you can send out notifications to all signed up volunteers or easily edit your existing task and your volunteers will also be notified about it.</p>
                    <p>It is also possible to message people - sometimes they will reach out to you if they have questions. There will be a notification about incoming messages.</p>
                    <p>You can also delete a task but we strongly advise you to only do it well in advance and under special circumstances. Otherwise, it might affect your rating.</p>
                    <h3 className="margin-top">3. While Volunteering</h3>
                    <p>You are all settled - now it's time to do something good! Unfortunately, there are always people who are less reliable and won't show up. We are aware this is a big issue, so we encourage you to make use of our CHECK IN feature. This provides a QR code that volunteers can scan with their smart phone after arriving at the meeting point to detect those less reliable ones who will get a negative rating for no-shows. For future tasks, you can chose to only have reliable volunteers sign up. To be able to do that, you need to be on the level of "Super Organizer" - read more about the levels in the next paragraph.</p>
                    <h3 className="margin-top">4. After the task</h3>
                    <p>If you want, you can give individual volunteers a public review. They will appreciate your fair feedback and it also encourages them! Vice versa, volunteers can give you as the organizer an optional public review on your wall.</p>
                    <p>Once completed, your profile will count this task into "tasks offered" and into the combined hours volunteered - the combined "volunteer power" you have enabled and unleashed by providing your task.</p>
                    <p>After successfully offering at least 10 tasks and having used the CHECK IN function at least 7 times, you will go from "Organizer Freshman" to "Super Organizer". And after 20 tasks offered, you will become "Organizer Hero"!</p>

                    <div className="margin-top grey-box">
                    <h3>Are you a volunteer?</h3>
                    <p>If you are a volunteer and interested in finding and participating in short-term tasks, you can find more information <Link to="/">here</Link>.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrganizerGuide;
