import React from 'react';
import { Link } from 'react-router-dom';
import QRcode from './qrcode.png';

const CheckIn = (props) => {
    return (
        <div className="main text-center">
            <h1>Check In for Clean up Søerne &nbsp;• &nbsp; Saturday, 27.03.2019</h1>
            <div className="dashboard-box">
                <div className="dashboard-text text-center">
                    <p>Volunteers just scan the QR code below with a QR reader to check in for the task.</p>
                    <img src={QRcode} alt="" />
                </div>
                <Link to="/dashboard" className="button-green margin-top">Back to Dashboard</Link>
        </div>
        </div>
    );
}

export default CheckIn;
