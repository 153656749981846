import React from 'react';
import { Link } from 'react-router-dom';

const SignUp = (props) => {
    return (
        <div className="main page-login">
            <h1>Sign Up</h1>
            <div className="full-width-box">
                <h2>Register as an Organizer</h2>
                <form onSubmit={props.handleSignUp}>
                    <input type="text" name="organizationname" placeholder="Name of the Organization" /><br/>
                    <input type="text" name="contactpersonname" placeholder="Name of Contact Person" /><br/>
                    <input type="email" name="usermail" placeholder="E-mail address" /><br/>
                    <input type="password" name="userpw" placeholder="Password" /><br/>
                    <input type="password" name="userpw" placeholder="Confirm Password" />
                    <br/>
                    <input type="submit" value="Sign up" className="button-green" />
                </form>
            </div>
            <div className="full-width-box">
                <h2>Already have an account?</h2>
                <Link to="/login" className="button-green">Go to log in</Link>
            </div>
            <div className="text-center">
                Are you a volunteer? Log in and sign up <a href="https://organizers.spareanhour.org/login">here</a>.
                <br/>
            </div>
        </div>
    );
}

export default SignUp;
