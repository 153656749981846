import React from 'react';
import { Link } from 'react-router-dom';
import headerLogo from './header-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faUser, faBell, faTasks } from '@fortawesome/free-solid-svg-icons';

const Header = (props) => {
    return (
        <header className="header">
            <Link to="/"><img src={headerLogo} className="header-logo" alt="Logo of Spare an Hour" /></Link>
            <nav className="header-nav">
                <ul>
                    {!props.loggedIn && <li><Link to="/organizer-guide">Guide for Organizers</Link></li>}
                    {!props.loggedIn && <li><a href="https://spareanhour.org/about">About</a></li>}
                    {!props.loggedIn && <li><Link to="/login">Log in</Link></li>}
                    {props.loggedIn &&<li><Link to="/dashboard"><FontAwesomeIcon icon={faTasks} /> Dashboard &nbsp;</Link></li>}
                    {props.loggedIn && <li><Link to="/messages"><FontAwesomeIcon icon={faBell} /> Messages &nbsp;</Link></li>}
                    {!props.loggedIn && <li className="main-nav-highlight"><a href="https://spareanhour.org">For Volunteers</a></li>}
                    {props.loggedIn &&
                        <li className="dropdown"><Link to="/dashboard"><FontAwesomeIcon icon={faUser} /> My Profile <FontAwesomeIcon icon={faCaretDown} /></Link>
                            <div className="dropdown-content">
                                <ul>
                                    <li><Link to="/organizer-profile">View Profile</Link></li>
                                    <li><Link to="/organizer-profile">Update Profile</Link></li>
                                    <li><button onClick={props.handleLogout}>Log out</button></li>
                                </ul>
                            </div>
                        </li>
                    }
                </ul>
            </nav>
        </header>
    );
}

export default Header;
