import React from 'react';
import { Link } from 'react-router-dom';

const PastTasks = (props) => {
    return (
        <div className="task-box-small-container">
            <Link to="/task/id" className="link-grey"><img src={props.task.img} className="text-box-small-img" />
            <span className="margin-top font-smaller-uppercase">{props.task.date}</span><br/>
            <span className="text-bold">{props.task.title}</span><br/>
            <span>{props.task.signups}/{props.task.signupsmax} volunteered</span></Link>
        </div>
    )
}

export default PastTasks;