import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';

import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './Home';
import Login from './Login';
import SignUp from './SignUp';
import Dashboard from './dashboard/Dashboard';
import TaskView from './TaskView/TaskView';
import OrganizerGuide from './OrganizerGuide';
import OrganizerProfile from './Profile/OrganizerProfile';
import CheckIn from './dashboard/CheckIn';
import CreateNewTask from './TaskView/CreateNewTask';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false
    };

    this.handleLoginButtonClicked = this.handleLoginButtonClicked.bind(this);
    this.handleLogoutButtonClicked = this.handleLogoutButtonClicked.bind(this);
  }

  handleLoginButtonClicked(event) {
    event.preventDefault();
    this.setState({
      loggedIn: true
    });
    this.props.history.push('/dashboard');
  }

  handleLogoutButtonClicked() {
    this.setState({
      loggedIn: false
    });
    this.props.history.push('/');
  }

  render() {
    return (
      <div className="App">
        <Header loggedIn={this.state.loggedIn} handleLogout={this.handleLogoutButtonClicked} />
        <section>
          <Route path="/" exact component={Home}/>
          <Route path="/login" render={() => <Login handleLogin={this.handleLoginButtonClicked} />} />
          <Route path="/sign-up" render={() => <SignUp handleSignUp={this.handleLoginButtonClicked} />} />
          <Route path="/dashboard" component={Dashboard}/>
          <Route path="/task" component={TaskView} />
          <Route path="/organizer-guide" component={OrganizerGuide} />
          <Route path="/organizer-profile" component={OrganizerProfile} />
          <Route path="/checkin" component={CheckIn} />
          <Route path="/create-new" component={CreateNewTask} />
        </section>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
