import React from 'react';
import { Link } from 'react-router-dom';
import OrganizerTaskBox from './OrganizerTaskBox';
import PastTasks from './PastTasks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCopy, faPlus } from '@fortawesome/free-solid-svg-icons';

const upcomingTasks = [
    {
        img: require("./cleanup-img.png"),
        title: "Clean up Søerne",
        signups: 37,
        signupsmax: 45,
        date: "Saturday, 27.03.2019",
        location: "Intersection Sortedams Dossering/Nørrebrogade",
        status: "scheduled",
    },
    {
        img: require("./food.jpg"),
        title: "Clean up Nordvest",
        signups: 7,
        signupsmax: 45,
        date: "Sunday, 08.04.2019",
        location: "Bispebjerg Station",
        status: "scheduled",
    }
]

const pastTasks = [
    {
        img: require("./cleanup-img.png"),
        title:"Christmas Cleanup",
        signups: 43,
        signupsmax: 45,
        date: "Saturday, 25.12.2018",
        location:"Intersection Sortedams Dossering/Nørrebrogade",
        status: "Scheduled",
    },
    {
        img: require("./sample.jpg"),
        title:"Paint Walls",
        signups: 28,
        signupsmax: 35,
        date: "Saturday, 09.11.2018",
        location: "Bispebjerg Station",
        status: "Scheduled"
    },
    {
        img: require("./cleanup-img.png"),
        title:"Clean Up Søerne",
        signups: 45,
        signupsmax: 45,
        date: "Friday, 28.08.2018",
        location: "Bispebjerg Station",
        status: "Scheduled"
    }
]

const Dashboard = (props) => {
    return (
        <div className="main">
            <h1 className="text-center">Hello, Organizer!</h1>
            <div className="green-box text-center margin-top">
                <h3>Create new task</h3>
                <Link to="/create-new" className="button-green-box margin-right"><FontAwesomeIcon icon={faPlus} /> Create new</Link> <Link to="/task/id/edit" className="button-green-box"> <FontAwesomeIcon icon={faCopy} /> Copy existing</Link>
            </div>
            <div className="orgdashboard-upcoming">
                <div className="db-header margin-top">
                    <h3>Your upcoming events</h3>
                    <Link to="/" className="link-grey margin-top-11"><FontAwesomeIcon icon={faSearch} /> Search Task</Link>
                </div>
                <div>
                    {upcomingTasks.map((upcomingTasks) => 
                        <div className="db-card"><OrganizerTaskBox task={upcomingTasks} /></div>
                    )}
                </div>
                <div className="db-header">
                    <h3>Your past events</h3>
                    <div class="commands" />
                </div>
                <div className="task-box-small-row">
                {pastTasks.map((pastTasks) => <PastTasks task={pastTasks} /> )}
            </div>        
            </div>
            <div className="grey-box margin-top">
            <h3>Your stats</h3>
                <p>Total tasks offered: 13<br/>Volunteers participated: 92<br/>Combined volunteering hours unleashed: 426</p>
            </div>
        </div>
    );
}

export default Dashboard;