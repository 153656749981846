import React from 'react';
import { Link } from 'react-router-dom';
import homeBanner from './man-laptop.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

const Home = (props) => {
    return (
        <div className="home-wrapper">
            <img src={homeBanner} className='home-banner-image' />
            <div className="main">
                <div className="home-text-wrapper">
                    <h1 className="text-center margin-top">Welcome to Spare an Hour<br/>For Organizers</h1>
                    <p>Spare An Hour connects volunteers looking for short-term jobs with organizers. More specific, short-term tasks that can be done within a few hours, such as a Saturday afternoon, allowing the volunteer maximal flexibility and choice of task.</p>
                    <p>As an organizer, you can offer as many short-term tasks as you want - for free. Because we believe that doing good should not be charged.</p>
                    <p>You will find a lot of motivated people that can easiliy sign up for your tasks. And with Spare an Hour, you will get a tool to easily manage jobs and volunteers - so you can focus on what is important: the volunteer work.</p>
                    <p>Interested? Just give it a go!</p>
                    <div className="text-center">
                        <Link to="/organizer-guide" className="button-plain text-center">How it works <FontAwesomeIcon icon={faLongArrowAltRight} /></Link>
                    </div>
                </div>
                <div className="home-cta-wrapper margin-top">
                    <div className="grey-box cta-box">
                        <h2>Log in</h2>
                        <Link to="/login" className="button-green">Log in</Link>
                    </div>
                    <div className="grey-box cta-box">
                        <h2>Not registered yet?</h2>
                        <Link to="/sign-up" className="button-green">Join us</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
