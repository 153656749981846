import React from 'react';
import { Link } from 'react-router-dom';

import cleanupImg from './cleanup-img.png';
import testOrganization from './testorga.png';
import locationImg from './location.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEnvelope, faMapMarkerAlt, faPen, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const CreateNewTask = (props) => {
    return (
        <div className="main">
        <h1>Create New Task</h1>
            <div className="taskview-taskdetails">
                <div className="edit-detail-text">
                    <input className="edit-border small-box header" placeholder="Add task date here (e.g. Saturday, 26.04.)" />
                    <div className="select-wrapper">
                        <select>
                            <option value="Environment">SELECT TASK TYPE</option>
                            <option value="Environment">Nature & Environment</option>
                            <option value="Environment">Cultural Activities</option>
                            <option value="Environment">Social Work</option>
                            <option value="Environment">Humanitary Work</option>
                            <option value="Environment">Educational</option>
                        </select>
                    </div>
                    <input className="edit-border small-box header" placeholder="Add task title here" />
                    <input className="edit-border big-box" type="text" placeholder="Write task description here" />

                    <h3>Additional Information</h3>
                    <input className="edit-border big-box" type="text" placeholder="Add additional information or requirements here" />
                    <div className="text-center">
                        <Link to="/confirmation" className="button-green button-long">Create Task Now</Link>
                    </div>
                </div>
                    <div className="detail-sidebar">
                        <img src={cleanupImg} className="details-img" alt="" /><br/>
                        <img src={locationImg} className="details-img" alt="Location of task" /><br/>
                        <div className="location-box">
                        <br/>
                            <div className="dashboard-box-details">
                                <div className="icon-wrapper"><FontAwesomeIcon icon={faMapMarkerAlt} /></div>
                                <span><span className="text-bold">MEETING POINT<FontAwesomeIcon icon={faPen} className="edit-pen" /></span><br/>
                                Add task location here</span>
                            </div>
                            <div className="dashboard-box-details">
                                <div className="icon-wrapper"></div>
                                <span><span className="text-bold">TIME<FontAwesomeIcon icon={faPen} className="edit-pen" /></span><br/>
                                Add task time here</span>
                            </div>
                            <div className="progress-bar">
                                <div className="filler" style={{width: '100%'}} />
                                <span>Enter amount of slots<FontAwesomeIcon icon={faPen} className="edit-pen" /></span>
                            </div>
                        </div>
                    </div>
            </div>
            <div className="grey-box organizer-box">
                    <div className="dashboard-text">
                        <h3>ABOUT THE ORGANIZER</h3>
                        <div className="edit-border">
                            <FontAwesomeIcon icon={faPen} className="edit-pen" /><br/>
                            <p>Formålet med Copenhagen Clean Up initiativet er, at skabe en fælles ejerskabs- og stolthedsfølelse iblandt Københavnerne. Vi håber, at man - via sin aktive og personlige deltagelse i skraldeoprydning - får en lyst til at opretholde en god etik omkring forbrug og afskaffelse af skrald. At man sørger for, at afskaffe sit eget skrald på ansvarlig vis og ikke mindst påminder andre medborgere om deres personlige ansvar, hvis de er uhensigtsmæssige omkring afskaffelsen af deres skrald - en venlig påmindelse fra en medborger kan gøre et stort indtryk, så man næste gang husker, at afskaffe sit skrald på ansvarlig vis.</p> 
                            <p>Lad os gå sammen og Make Copenhagen Clean Again!</p>
                            </div>
                        </div>
                        <div className="organizer-img-wrapper">
                            <Link to="/organizer-profile" className="link-grey"><span>COPENHAGEN CLEAN UP</span>
                            <img src={testOrganization} className="organizer-image" alt="" /></Link>
                            <Link to="/messages" className="link-grey"><span><FontAwesomeIcon icon={faEnvelope} className="icon" />Contact</span></Link>
                        </div>
                </div>
        </div>
    );
}

export default CreateNewTask;
