import React from 'react';
import { Link } from 'react-router-dom';

import './TaskView.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faAngleDown } from '@fortawesome/free-solid-svg-icons';

const sampleVolunteers = [
    {name: 'Julia', signup: 'Mon, 7th Dec, 13:07', rating: 4},
    {name: 'Kasper', signup: 'Mon, 7th Dec, 17:07', rating: 5},
    {name: 'Lukas', signup: 'Tue, 8th Dec, 09:27', rating: 3},
    {name: 'Laura', signup: 'Tue, 8th Dec, 23:11', rating: 4},
    {name: 'Hans', signup: 'Mon, 11th Dec, 17:47', rating: 5},
]

const VolunteerRow = (props) => {
    return (
        <tr>
            <td className='volunteer-cell'>
                <div className='image-missing'>
                    <FontAwesomeIcon icon={faUser} />
                </div>
                <div>
                    <b>{props.user.name}</b><br />
                    <span><a href="https://spareanhour.org/user" className="link-grey">View profile</a></span>
                </div>
            </td>
            <td>{props.user.signup}</td>
            <td>
                {'★'.repeat(props.user.rating)}
                {'☆'.repeat(5-props.user.rating)}
            </td>
            <td><Link to="/" className='button-green button-hollow text-center'>Send message</Link></td>
        </tr>
    );
}

const TaskViewVolunteers = (props) => {
    return (
        <div className='taskview-volunteers'>
            <header>
                <h3>Clean Up Søerne, 16.02. at 10:00</h3>
                <Link to="/" className='button-green text-center'>Message all</Link>
            </header>
            <table className='volunteer-list'>
                <tr>
                    <th>Volunteer</th>
                    <th>Sign up date</th>
                    <th>Rating</th>
                    <th>Messaging</th>
                </tr>
                { sampleVolunteers.map((volunteer) => <VolunteerRow user={volunteer} />) }
                <tr><td colspan='4' className='load-more'>
                    <FontAwesomeIcon icon={faAngleDown} /> Load more...
                </td></tr>
            </table>
        </div>
    );
}

export default TaskViewVolunteers;
